import { deleteApi, getApi, postApi, putApi } from "./methods";

const Api = {
  signUp: "/admin/signupAdmin",
  signIn: "/admin/loginAdmin",
  getUser: "/adminUser/getUsers?page=",
  delUser: "/adminUser/deleteUserAccount?UserId=",
  searchUser: "/admin/searchUser?search=",
  createTeam: "/team/createTeam",
  addteamMember: "/team/createMembers",
  getteamList: "/team/getTeamsData",
  deleteTeam: "/team/deleteTeam?teamId=",
  editTeam: "/team/editTeam",
  searchTeam: "/team/searchTeams?search=",
  getteammember: "/adminList/getMembersData?",
  deleteTeamMember: "/team/deleteMembers?memberId=",
  editTeamMember: "/team/updateMembers",
  searchMember: "/admin/searchUser?search=",
  createVehicle: "/adminVehicle/createVehicle",
  getVehiclesList: "/adminVehicle/getAllVehicles?page=",
  deleteVehicle: "/adminVehicle/deleteVehicle?vehicleId=",
  editVehicle: "/adminVehicle/editVehicle?vehicleId=",
  searchVehicle: "/adminVehicle/searchVehicle?search=",
  getProjects: "/adminProject/getProjectsData?limit=",
  deleteProject: "/adminProject/deleteProjects?projectId=",
  acceptRejectRequest: "/adminProject/acceptProjectRequest",
  getNewRequests: "adminProject/getAllProjectRequests?page=",
  getMemberList: "/adminProject/getFreeMembersData",
  getMemberSubmit: "/adminProject/assignVehicleAndMemberToProject",
  getfreevehicleList: "/adminProject/getFreeVehicleData",
  createequipment: "/equipments/createEquipment",
  getequipmentlist: "/equipments/getAllFreeEquipment",
  getallequipmentlist: "equipments/getAllEquipment",
  getequipmentsearch: "/equipments/searchEquipments",
  completetask: "/adminProject/completeProjectRequest",
  labourlisting: "/adminList/getAdminMembersData",
  completedtask: "/adminProject/getCompletedProjectsData?",
  inprogresstask: "/adminProject/getInProgressProjectsData?",
  rejectedtask: "/adminProject/getRejectedProjectsData?",
  searchtask: "/adminProject/searchRequests?",
  allprojects: "/adminProject/getAllRequestData?",
  refreshAdminList: "/adminList/refreshAdminsList?",
  allprocoreprojects: "/projects/getAllUserProjects",
  allnotificationusers: "/admin/getAllNotificationUsers",
  fetchTimecardEntries: "/projects/getTimecardEntries",
  fetchQuantitiesEntries: "/projects/getQuantityEntries",  // Hypothetical endpoint for quantities data
  fetchEquipmentEntries: "/projects/getEquipmentEntries",
  sendnotification: "/admin/sendNotifications",
  ilovepdflistpaged: "/admin/ilovepdf/list",
  createCustomSigning: "/admin/ilovepdf/createCustomSignature",
  deleteSigning: "/admin//ilovepdf/voidSignature"
};

const pageLimit = 10;

export const deleteSigning = (payload) => {
  return putApi(Api.deleteSigning, payload);
}

export const iLovePDFlistpaged = () =>
{
  return getApi(Api.ilovepdflistpaged);
}

export const createCustomSigning = (payload) => {
  return postApi(Api.createCustomSigning, {payload});
};

export const getTimecardList = (project_id, start_date, end_date) => {
  return getApi(
    `${Api.fetchTimecardEntries}?project_id=${project_id}&start_date=${start_date}&end_date=${end_date}`
  );
};

export const getQuantitiesList = (project_id, start_date, end_date) => {
  return getApi(
    `${Api.fetchQuantitiesEntries}?project_id=${project_id}&start_date=${start_date}&end_date=${end_date}`
  );
};

export const getEquipmentList = (project_id, start_date, end_date) => {
  return getApi(
    `${Api.fetchEquipmentEntries}?project_id=${project_id}&start_date=${start_date}&end_date=${end_date}`
  );
};

export const getAllProcoreProjectsApi = () => {
  return getApi(Api.allprocoreprojects);
}

export const getAllNotificationUsers = () => {
  return getApi(Api.allnotificationusers);
}

export const pushNotification = (payload) => {
  return postApi(Api.sendnotification, payload);
}

export const refreshAdminListApi = (page, limit = pageLimit, refresh_token) => {
  return postApi(Api.refreshAdminList+ "page=" + page + "&limit=" + limit + "&refresh_token="+refresh_token, {});
};

export const signUpApi = (payload) => {
  return postApi(Api.signUp, payload);
};

export const signInApi = (payload) => {
  return putApi(Api.signIn, payload);
};

export const getUserListApi = (page, limit = pageLimit, accessToken) => {
  return getApi(
    Api.getUser + page + "&limit=" + limit + "&accessToken=" + accessToken
  );
};

export const delUserListApi = (id) => {
  return deleteApi(Api.delUser + id);
};

export const searchUserApi = (searchText, page, limit = pageLimit) => {
  return getApi(
    Api.searchUser + searchText + "&page=" + page + "&limit=" + limit
  );
};

export const createTeamApi = (payload) => {
  return postApi(Api.createTeam, payload);
};

export const addTeamMemberApi = (payload) => {
  return postApi(Api.addteamMember, payload);
};

export const getTeamListApi = (page, limit = pageLimit) => {
  return getApi(Api.getteamList + "?page=" + page + "&limit=" + limit);
};

export const deleteTeamApi = (id) => {
  return deleteApi(Api.deleteTeam + id);
};
        
export const editTeamApi = (payload) => {
  return putApi(Api.editTeam, payload);
};

export const searchTeamApi = (searchText, page, limit = pageLimit) => {
  return getApi(
    Api.searchTeam + searchText + "&page=" + page + "&limit=" + limit
  );
};

export const getTeamMemberApi = (page, limit = pageLimit, refresstoken) => {
  return getApi(
    Api.getteammember +
      "page=" +
      page +
      "&limit=" +
      limit +
      "&refresh_token=" +
      refresstoken
  );
};

export const deleteMemberApi = (id) => {
  return deleteApi(Api.deleteTeamMember + id);
};

export const editTeamMemberApi = (payload) => {
  return putApi(Api.editTeamMember, payload);
};

export const searchMemberApi = (searchText, page, limit = pageLimit) => {
  return getApi(
    Api.searchMember + searchText + "&page=" + page + "&limit=" + limit
  );
};

export const createVehicleApi = (payload) => {
  return postApi(Api.createVehicle, payload);
};

export const getVehicleListApi = (page, limit = pageLimit) => {
  return getApi(Api.getVehiclesList + page + "&limit=" + limit);
};

export const deleteVehicleApi = (vehicleId) => {
  return deleteApi(Api.deleteVehicle + vehicleId);
};

export const editVehicleApi = (vehicleId, payload) => {
  return putApi(Api.editVehicle + vehicleId, payload);
};

export const searchVehicleApi = (
  searchText,
  page,
  limit = pageLimit,
  filterPath
) => {
  return getApi(
    Api.searchVehicle +
      searchText +
      "&page=" +
      page +
      "&limit=" +
      limit +
      filterPath
  );
};

export const getProjectsApi = (limit, page) =>
  getApi(Api.getProjects + limit + "&page=" + page);

export const deleteProjectApi = (projectId) =>
  deleteApi(Api.deleteProject + projectId);

export const updateRequestApi = (payload) => {
  return putApi(Api.acceptRejectRequest, payload);
};

export const getRequestsApi = (page, limit = pageLimit,project_id) => {
  return getApi(Api.getNewRequests + page + "&limit=" + limit+"&project_id="+project_id);
};

export const getMemberApi = () => {
  return getApi(Api.getMemberList);
};

export const getMemberSubmit = (payload) => {
  return putApi(Api.getMemberSubmit, payload);
};

export const getfreevehiclelist = (payload) => {
  return getApi(Api.getfreevehicleList);
};

export const createEquipmentApi = (payload) => {
  return postApi(Api.createequipment, payload);
};

export const allfreeEquipmentlist = (payload) => {
  return getApi(Api.getequipmentlist, payload);
};

export const getAllEquipmentlistapi = (page, limit) => {
  return getApi(`${Api.getallequipmentlist}?page=${page}&limit=${limit}`);
};

export const getAllEquipmentsearch = (page, limit, search) => {
  return getApi(
    `${Api.getequipmentsearch}?page=${page}&limit=${limit}&search=${search}`
  );
};

export const getcompletetask = (payload) => {
  return putApi(Api.completetask, payload);
};

export const getlabourlisting = (page, per_page, refresstoken) => {
  return getApi(
    `${Api.labourlisting}?page=${page}&per_page=${per_page}&refresh_token=${refresstoken}`
  );
};

export const getcompletedtaskapi = (page, limit,project_id) => {
  return getApi(`${Api.completedtask}page=${page}&limit=${limit}&project_id=${project_id}`);
};

export const getRejectedtaskapi = (page, limit) => {
  return getApi(`${Api.rejectedtask}page=${page}&limit=${limit}`);
};

export const getinprogrsstaskapi = (page, limit,project_id) => {
  return getApi(`${Api.inprogresstask}page=${page}&limit=${limit}&project_id=${project_id}`);
};

export const getsearchdata = (page, limit, search, isAccept) => {
  return getApi(
    `${Api.searchtask}page=${page}&limit=${limit}&search=${search}&isAccept=${isAccept}`
  );
};

export const getAlllist = (page, limit,refresh_token,project_id) => {
  return getApi(`${Api.allprojects}limit=${limit}&page=${page}&refresh_token=${refresh_token}&project_id=${project_id}`);
};
