import {
  Routes,
  Route,
  Navigate,
  Outlet,
  BrowserRouter,
} from "react-router-dom";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import LandingPage from "./pages/landingPage";
// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";
import Team from "./pages/team/projectMember";
import { Vehicle } from "./pages/vehicle";
import { ProjectManagement } from "./pages/project";
import TeamMembers from "./pages/team/memberList";
import NewRequests from "./pages/project/newRequest";
import Equipment from "./pages/Equipment";
import { AdminManagment } from "./pages/labour/Labourmangment";
import Complete from "./pages/CompletedProject/Complete";

import Rejected from "./pages/Rejectedproject/Rejected";
import Requestmanagment from "./pages/RequestManagment/Requestmanagment";
import UserDetail from "./pages/RequestManagment/UserDetail";

import { DailyLog } from "./pages/DailyLogs/DailyLogs";
import { NotificationCenter } from "./pages/NotificationCenter/notificationCenter";
import { DocumentManagement } from "./pages/DocumentManagement/documentManagement";
function PublicRoute({ isAuthenticated }) {
  if (isAuthenticated) return <Navigate to="/members" replace />;
  return <Outlet />;
}

function PrivateRoute({ isAuthenticated }) {
  if (!isAuthenticated) return <Navigate to="/" replace />;
  return <Outlet />;
}

function App() {
  const { token } = useSelector(({ signIn }) => signIn?.data) || {};
  const loadingdata=useSelector((e)=>e?.Loadingdata?.data )
  console.log(loadingdata,"ll")



  return (
   <>
  
   
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute isAuthenticated={token} />}>
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
        </Route>
        <Route element={<PrivateRoute isAuthenticated={token} />}>
          <Route path="" element={<LandingPage />}>
            {/* <Route path="/users" element={<User />} /> */}
            <Route path="/members" element={<Team />} />
            <Route path="/team-members" element={<TeamMembers />} />
            <Route path="/vehicle" element={<Vehicle />} />
            <Route path="/request" element={<Requestmanagment />} />
            <Route path="/project-management" element={<ProjectManagement />} />
            <Route path="/equipment-management" element={<Equipment />} />
            <Route path="/new-requests" element={<NewRequests />} />
            <Route path="/admin-management" element={<AdminManagment />} />
            <Route path="/completed-projects" element={<Complete />} />
            <Route path="/rejected-projects" element={<Rejected />} />
            <Route path="/userdetails" element={<UserDetail/>} />
            <Route path="/daily-log-generator" element={<DailyLog />} />
            <Route path="/notification-center" element={<NotificationCenter />} />
            <Route path="/document-management" element={<DocumentManagement />} />
            {/* <Route path="/equipment-management" element={<Equipment />} /> */}
          </Route>
        </Route>
        {/* <Route path="*" element={<Notfound />} /> */}
      </Routes>
    </BrowserRouter> 
   
    </>
  );
}
///testing

export default App;
